import { API_URL } from "../utils/config";

async function getDistributoriPreferiti(lat=null, lng=null, preferiti=[]) {
    try {
        const response = await fetch(API_URL + 'distributoriPreferitiLocal?lat=' + lat + '&lon=' + lng+'&id[]='+preferiti.join('&id[]='));
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export default getDistributoriPreferiti;