import React from 'react';
import { Typography, Chip, List, ListItemButton, ListItemText } from '@mui/material';

const Distributore = ({distributore}) => {
    return (
        <>
            <Typography variant="overline">{distributore.bandiera}</Typography>
            <Typography className="serif" sx={{ fontFamily: 'IBM Plex Serif', fontWeight: '700' }} variant="h5">{distributore.gestore}</Typography>
            <Typography variant="body2">Ultimo aggiornamento {distributore.ultimo_aggiornamento}</Typography>
            <Typography variant="overline" sx={{marginBottom: '11px'}}>{distributore.indirizzo}</Typography>
            {distributore.prezzi.map(valore => (
              <>
              {(valore.self=="1") ? (
                <>
                <Typography variant="body1" sx={{marginBottom: '4px'}}><Chip label={"Self service"} color="success" /> Prezzo {valore.carburante}: {valore.prezzo}</Typography>
              </>
              ) : (
                <>
                  <Typography variant="body1" sx={{marginBottom: '4px'}}><Chip label={"Servito"} color="primary" /> Prezzo {valore.carburante}: {valore.prezzo}</Typography>
                </>
              )}
              </>
            ))}
            <List dense={true}>
              <ListItemButton component="a" href={`https://www.google.com/maps/search/?api=1&query=${distributore.latitudine}%2C${distributore.longitudine}`}>
                <ListItemText primary="Portami con Google Maps" />
              </ListItemButton>
            </List>
        </>
    );
}

export default Distributore;