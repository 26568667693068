import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './utils/theme';
import Home from './pages/home';
import MappaPage from './pages/mappa';
import LoadingScreen from './components/website/LoadingScreen';
import './styles/app.css';
import Header from './components/website/header';
import ViciniPage from './pages/vicini';
import PreferitiPage from './pages/preferiti';
import CreditsPage from './pages/credits';
import Barracookie from './components/website/barracookie';
import "vanilla-cookieconsent/dist/cookieconsent.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LoadingScreen />
      <Router>
        <Header />
        <Routes>
          <Route path="/" exact element={<><Home /></>} />
          <Route path="/mappa" exact element={<><MappaPage /></>} />
          <Route path="/vicini" exact element={<><ViciniPage /></>} />
          <Route path="/preferiti" exact element={<><PreferitiPage /></>} />
          <Route path="/credits" exact element={<><CreditsPage /></>} />
        </Routes>
        <Barracookie />
      </Router>
    </ThemeProvider>
  );
}

export default App;
