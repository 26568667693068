import React, { useState, useEffect } from "react";
import * as CookieConsent from "vanilla-cookieconsent";

const Barracookie = () => {
    useEffect(() => {
        CookieConsent.run({
          cookie: {
            name: 'cookieconsentiti',
            // domain: location.hostname,
            // path: '/',
            // sameSite: "Lax",
            // expiresAfterDays: 365,
          },
    
          guiOptions: {
            consentModal: {
              layout: 'cloud inline',
              position: 'bottom center',
              equalWeightButtons: true,
              flipButtons: false,
                theme: 'edgeless',
            },
            preferencesModal: {
              layout: 'box',
              equalWeightButtons: true,
              flipButtons: false
            }
          },
    
          onFirstConsent: ({ cookie }) => {
            //console.log('onFirstConsent fired', cookie);
          },
    
          onConsent: ({ cookie }) => {
            //console.log('onConsent fired!', cookie)
          },
    
          onChange: ({ changedCategories, changedServices }) => {
            //console.log('onChange fired!', changedCategories, changedServices);
          },
    
          onModalReady: ({ modalName }) => {
            //console.log('ready:', modalName);
          },
    
          onModalShow: ({ modalName }) => {
            //console.log('visible:', modalName);
          },
    
          onModalHide: ({ modalName }) => {
            //console.log('hidden:', modalName);
          },
    
          categories: {
            necessary: {
              enabled: true,  // this category is enabled by default
              readOnly: true  // this category cannot be disabled
            },
            analytics: {
              enabled: true,
              autoClear: {
                cookies: [
                  {
                    name: /^_ga/,   // regex: match all cookies starting with '_ga'
                  },
                  {
                    name: '_gid',   // string: exact cookie name
                  }
                ]
              },
    
              services: {}
            },
            ads: {}
          },
    
          language: {
            default: 'it', // Change the default language to Italian
            translations: {
              it: {
                consentModal: {
                  title: 'Informazioni sull\'uso dei cookies',
                  description: 'Questo sito web utilizza cookie essenziali per garantire il suo corretto funzionamento e cookie di terze parti per fornire servizi erogati da portali esterni. Questi ultimi saranno impostati solo dopo il consenso.<br><br><br><button type="button" data-cc="c-settings" class="cc-link" aria-haspopup="dialog">Fammi scegliere</button>',
                  acceptAllBtn: 'Accetta tutti',
                  acceptNecessaryBtn: 'Rifiuta tutto',
                  showPreferencesBtn: 'Gestisci preferenze',
                  revisionMessage: '<br><br> I termini e le condizioni sono state modificate dalla tua ultima visita!'
                },
                preferencesModal: {
                  title: 'Impostazione cookies',
                  saveSettingsBtn: 'Salva la selezione corrente',
                  acceptAllBtn: 'Accetta tutto',
                  rejectAllBtn: 'Rifiuta tutto',
                  closeBtnLabel: 'Chiudi',
                  cookieTableHeaders: [
                    { col1: 'Nome' },
                    { col2: 'Dominio' },
                    { col3: 'Descrizione' },
                    { col4: 'Durata' },
                  ],
                  sections: [
                    {
                      title: "Uso dei cookie",
                      description: "Questo sito utilizza i cookie per migliorare la tua esperienza di navigazione. I cookie indicati come necessari sono memorizzati nel tuo browser, poiché sono essenziali per le funzionalità base del sito. Usiamo anche cookie di terze parti che ci permettono di analizzare e capire come utilizzi questo sito. Questi cookie saranno memorizzati nel tuo browser solo con il tuo consenso. Hai anche l'opzione di non aderire a questi cookie. Ciò potrebbe comportare conseguenze nella tua esperienza di navigazione.<br><br>Vedi la <a href=\"/cookie-e-privacy\" class=\"cc-link\">Privacy Policy</a> completa"
                    },
                    {
                      title: "Cookie necessari",
                      description: "I cookie necessari sono assolutamente essenziali per il corretto funzionamento del sito web. Questi cookie garantiscono le funzionalità di base e le caratteristiche di sicurezza del sito web, in modo anonimo.",
                      linkedCategory: 'necessary'
                    },
                    {
                      title: 'Cookies di terze parti',
                      description: "I cookie di terze parti sono quei cookie rilasciati non direttamente dal sito ma da sistemi terzi utilizzati all'interno del sito, per esempio l'inclusione in pagina di un video della piattaforma YouTube",
                      linkedCategory: 'thirdpart'
                    },
                    {
                      title: "Cookie analitici",
                      description: "I cookie analitici raccolgono informazioni sul tuo utilizzo di questo sito web, quali pagine hai visitato e su quali link hai cliccato. Tutti i dati sono memorizzati in forma anonima e non possono in alcun modo ricondurre a te.",
                      linkedCategory: 'analytics',
                      cookieTable: {
                        caption: 'Cookies',
                        headers: {
                          name: 'Cookie',
                          domain: 'Dominio',
                          desc: 'Descrizione',
                          dur: 'Durata'
                        },
                        body: []
                      }
                    },
                    {
                      title: "Maggiori informazioni",
                      description: "<a class=\"cc-link\" href=\"mailto:privacy@urbancitytour.it\">Contattaci</a> per maggiori informazioni",
                    }
                  ]
                }
              }
            }
          }
          });
    }, []);
}

export default Barracookie;
