import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
          main: '#126604',
        },
        background: {
            default: '#031328',
            paper: '#031328',
            appbar: '#031328',
            toolbar: '#031328',
        },
    },
    typography: {
      fontFamily: 'Figtree',
    },
  });
export default theme;