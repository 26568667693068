import React, { useState, useEffect } from 'react';
import { Container, CircularProgress, createTheme, ThemeProvider, AppBar, Toolbar, Button, Typography, Link, IconButton, Menu, MenuItem } from '@mui/material';
import theme from '../../utils/theme';

function LoadingScreen() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
        setIsLoading(false);
        }, 2000);
    }, []);
    return (
        <>
        {isLoading && (
            <div style={{ display: 'flex', justifyContent: 'between', alignItems: 'center', flexDirection: 'column', width: '100%', height: '100svh', position: 'fixed', zIndex:'99999', backgroundColor: theme.palette.background.default, color: 'white' }} >
            <div></div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '-webkit-fill-available' }}>
              <div style={{textAlign: 'center'}}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, mb: 3 }}>Sto caricando MioCarburante!</Typography>
                <CircularProgress />
              </div> 
            </div>
            <Typography variant="body2" component="div" sx={{ flexGrow: 1, mb: 2 }}>Una webapp Urban City Tour</Typography>
          </div>
        )}
        </>
    );
  }

export default LoadingScreen;