import React from "react";
import { Container, Typography } from "@mui/material";

const CreditsPage = () => {
    return (
        <Container>
            <Typography variant="h4" style={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>Credits</Typography>
            <Typography variant="h6" gutterBottom>
                    Dati della Benzina
                </Typography>
                <Typography paragraph>
                    I dati sono forniti dal Ministero dei Trasporti e dal Ministero delle Imprese e del Made in Italy e sono soggetti alla licenza IODL 2.0.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Frequenza di aggiornamento
                </Typography>
                <Typography paragraph>
                    Quotidiana
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Software
                </Typography>
                <Typography paragraph>
                    Il software è stato sviluppato da Andrea Ottomanelli e Giancarlo Crudele nell'ambito della app Urban City Tour.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Mappe e Dati Geografici
                </Typography>
                <Typography paragraph>
                    Le mappe e i relativi dati sono forniti da OpenStreetMap.
                </Typography>
        </Container>
    );
};

export default CreditsPage;