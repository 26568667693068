import React from 'react';
import { AppBar, Container, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

const Header = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    return (
        <AppBar position="sticky">
          <Container style={{display: 'flex', alignItems: 'center', height: '60px'}}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to="/mappa">MioCarburante</Link>
          </Typography>
            <IconButton color="inherit" onClick={handleMenuOpen}>
              <MenuIcon />
            </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
          >
            <MenuItem onClick={handleMenuClose} component={Link} to="/mappa">Mappa</MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/preferiti">Preferiti</MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/vicini">Vicini a te</MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/credits">Credits</MenuItem>
          </Menu>
          </Container>
        </AppBar>
    )
}

export default Header;