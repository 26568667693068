import React, { useEffect, useState } from "react";
import { Box, List, ListItemButton, ListItemIcon, ListItemText, ListItemSecondaryAction, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, AppBar, Toolbar, IconButton, Typography, useMediaQuery, Button } from "@mui/material";
import getDistributori from '../hooks/getDistributori';
import Distributore from '../components/distributore/distributore';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../utils/theme';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import GradeIcon from '@mui/icons-material/Grade';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ViciniPage = () => {
    const [distributori, setDistributori] = useState([]);
    const [liveLocation, setLiveLocation] = useState(null);
    const [openModal, setOpenModal] = React.useState(false);
    const [distributoreSelezionato, setDistributoreSelezionato] = useState(null);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (liveLocation)
        getDistributori(liveLocation.latitude, liveLocation.longitude).then(data => {
            var distributori=data.distributori;
            var dda = [...distributori].sort((a, b) => a.distanza - b.distanza);
            setDistributori(dda);
          })
          .catch(error => {
            console.error('Errore durante il recupero dei distributori:', error);
          });
    }, [liveLocation]);
    useEffect(() => {
        if (!liveLocation)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const currentLocation = {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                };
                setLiveLocation(currentLocation);
              },
              (error) => {
                //console.error('Errore durante la geolocalizzazione:', error);
              }
            );
          } else {
            //console.error('Geolocalizzazione non supportata o mappa non pronta');
          }
    });
    const checkPreferito = (distributore) => {
        if (localStorage.getItem('distributoripreferiti')) {
            var preferiti = JSON.parse(localStorage.getItem('distributoripreferiti'));
            return preferiti.includes(distributore.id);
        }
        return false;
    }
    const handlePreferito = (distributore) => {
        if (localStorage.getItem('distributoripreferiti')) {
            var preferiti = JSON.parse(localStorage.getItem('distributoripreferiti'));
            if (preferiti.includes(distributore.id)) {
                preferiti = preferiti.filter((item) => item !== distributore.id);
            } else {
                preferiti.push(distributore.id);
            }
            localStorage.setItem('distributoripreferiti', JSON.stringify(preferiti));
        } else {
            localStorage.setItem('distributoripreferiti', JSON.stringify([distributore.id]));
        }
    }
    return (
        <>
            <Container>
            <Typography variant="h4" style={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>Distributori vicini</Typography>
                {liveLocation ? (
                    <List dense={true}>
                        {distributori.length>0 && distributori.map((distributore) => (
                            <ListItemButton onClick={() => { setDistributoreSelezionato(distributore);setOpenModal(true);}}>
                                <ListItemIcon sx={{marginRight:'5px'}}>
                                    <Typography variant="h5">{distributore.distanza}mt</Typography>
                                </ListItemIcon>
                                <ListItemText primary={<span style={{ textTransform: 'uppercase' }} dangerouslySetInnerHTML={{__html: `<b>${distributore.bandiera}</b> ${distributore.gestore}`}} />} secondary={distributore.indirizzo} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="preferito" onClick={(event) => { handlePreferito(distributore); } } >
                                        {checkPreferito(distributore) ? <GradeIcon/> : <GradeOutlinedIcon />}
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        ))}
                    </List>
                ) : (
                    <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      minHeight: '100vh',
                    }}
                  >
                    <Typography variant="h1" style={{ color: 'white' }}>
                      OPS!
                    </Typography>
                    <Typography variant="h6" style={{ color: 'white' }}>
                      Per visualizzare i distributori vicini a te devi abilitare la geolocalizzazione
                    </Typography>
                  </Box>
                )}
            </Container>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth="md"
                open={openModal}
                onClose={() => setOpenModal(false)}
                TransitionComponent={Transition}
            >
                {fullScreen && (
                    <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setOpenModal(false)}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                      <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {distributoreSelezionato && (distributoreSelezionato.bandiera)}
                      </Typography>
                      <Button autoFocus color="inherit" onClick={() => setOpenModal(false)}>
                        Chiudi
                      </Button>
                    </Toolbar>
                  </AppBar>
                )}
                <DialogContent>
                    <Distributore distributore={distributoreSelezionato} />
                </DialogContent>
                {!fullScreen && (<DialogActions>
                    <Button onClick={() => setOpenModal(false)}>Chiudi</Button>
                </DialogActions>)}
            </Dialog>
        </>
    );
};

export default ViciniPage;