import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, useMap, Marker, useMapEvents } from 'react-leaflet';
import L, { MarkerCluster, LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-cluster';
import getDistributori from '../../hooks/getDistributori';
import { Snackbar, Alert as MuiAlert, Slide, AppBar, Toolbar, IconButton, Typography, useMediaQuery, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import theme from '../../utils/theme';
import Distributore from '../distributore/distributore';
import CloseIcon from '@mui/icons-material/Close';
import { getDistance } from 'geolib';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MappaHome = () => {
  const [distributori, setDistributori] = useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openModal, setOpenModal] = React.useState(false);
  const [distributoreSelezionato, setDistributoreSelezionato] = useState(null);
  const [located, setLocated] = useState(false);
  const [alertZoom, setAlertZoom] = useState(false);

  const MyComponent = () => {
    const map = useMapEvents({
      moveend: (event) => {
        const newCenter = event.target.getCenter();
        const newZoom = event.target.getZoom();
        const previousCenter = map.getCenter();
        const previousZoom = map.getZoom();
        if (newZoom >= 14) {
          setAlertZoom(false);
          getDistributori(newCenter.lat, newCenter.lng)
            .then(data => {
              setDistributori(data);
            })
            .catch(error => {
              console.error('Errore durante il recupero dei distributori:', error);
            });
        } else if (newZoom < 14) {
          setAlertZoom(true);
          setDistributori([]);
        }
      },
    });
    return null
  }

  function LocationMarker({ located, setLocated}) {
    const map = useMap();
  
    useEffect(() => {
      if (!located) {
        map.locate();
        setLocated(true);
      }
    }, [map, located]);
  
    map.on('locationfound', (e) => {
      map.stopLocate();
      map.flyTo(e.latlng, 13);
    });
  
    return null;
  }

  const createClusterCustomIcon = function (cluster) {
    return L.divIcon({
      html: `<div><span class="font-sans">${cluster.getChildCount()}</span></div>`,
      className: "marker-cluster custom-marker-cluster marker-cluster-large",
      iconSize: L.point(33, 33, true)
    })
  }

  useEffect(() => {
    // Sovrascrivi il metodo _initTile di L.GridLayer.prototype
    var originalInitTile = L.GridLayer.prototype._initTile;
    L.GridLayer.include({
      _initTile: function (tile) {
        originalInitTile.call(this, tile);

        var tileSize = this.getTileSize();

        tile.style.width = tileSize.x + 1 + 'px';
        tile.style.height = tileSize.y + 1 + 'px';
      }
    });
  }, []);
  return (
    <>
      <MapContainer className="w-full h-[calc(100svh-60px)]" center={[41.13724901775989, 16.823130510930604]} zoom={13} scrollWheelZoom={false}>
        <MyComponent />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}{r}.png"
        />
        <LocationMarker located={located} setLocated={setLocated} />
        <MarkerClusterGroup chunkedLoading iconCreateFunction={createClusterCustomIcon}>
          {distributori && distributori.distributori && distributori.distributori.map((distributore, index) => (
            <Marker key={index} position={[distributore.latitudine, distributore.longitudine]} title={distributore.nome} icon={L.divIcon({ html: `<span class="font-sans">${distributore.bandiera.toUpperCase()}</span>`, className: "bg-transparent border-0 text-center flex items-center justify-center leading-none", iconSize: new L.Point(40, 47) })} eventHandlers={{
              click: () => {
                setDistributoreSelezionato(distributore);
                setOpenModal(true);
              }
            }}></Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Transition}
      >
        {fullScreen && (
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpenModal(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {distributoreSelezionato && (distributoreSelezionato.bandiera)}
              </Typography>
              <Button autoFocus color="inherit" onClick={() => setOpenModal(false)}>
                Chiudi
              </Button>
            </Toolbar>
          </AppBar>
        )}
        <DialogContent>
          <Distributore distributore={distributoreSelezionato} />
        </DialogContent>
        {!fullScreen && (<DialogActions>
          <Button onClick={() => setOpenModal(false)}>Chiudi</Button>
        </DialogActions>)}
      </Dialog>
      <Snackbar open={alertZoom} style={{marginRight:'80px', bottom:'70px', zIndex:9999}}>
        <MuiAlert icon={false} elevation={6} variant="filled" severity="warning">
          Aumenta lo zoom per visualizzare i distributori
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default MappaHome;